<template>
    <div>
        <h3>Кандидату в общественный совет</h3>
        <table>
            <tr>
                <td>Описание</td>
                <td class="drop-icon"></td>
            </tr>
            <tr>
                <td class="ckEditor-html" v-html="getCandidatePublicCouncil['f_text']"></td>
                <td class="drop-icon">
                    <router-link class="btn-blue" :to="`candidate_public_council/create`">
                        Редактировать
                    </router-link>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
  name: 'CandidatePublicCouncil',

  created () {
    this.$store.dispatch('setCandidatePublicCouncil')
  },
  computed: {
    getCandidatePublicCouncil () {
      return this.$store.getters.getCandidatePublicCouncil
    }
  }
}
</script>
<style lang="scss" scoped>
.ckEditor-html {
  ::v-deep {
    * {
      margin: revert;
      padding: revert;
      list-style-type: revert;
      font-size: revert;
      font-weight: revert;
    }
  }
}
h3 {
    font-weight: 600;
    font-size: 1.37rem;
    color: #1F3245;
    margin-bottom: 3.1rem;
  }
.blue{
    margin-bottom: 2rem;
}
  table{
    margin-top: 1rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        flex: auto;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;
        a {
          color: #ffffff;
        }
        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }
      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
      td:nth-child(2){
        width: 11rem;
      }
    }
  }
table:last-child tr{
    border-bottom: 0;
}
@media screen and (max-width: 768px){
}
@media screen and (max-width: 420px){
  .accordion-block{
    header{
      padding: 1.25rem 1.38rem;
      p{
        margin-right: .125rem;
      }
    }
  }
}
</style>
